(($) => {
    $(document).ready(() => {
        const ajaxModal = $('#ajax-modal');
        const ajaxModalBody = $('.js-ajax-modal-body');

        $('body').on('click', 'a.js-ajax-modal', (e) => {
            e.preventDefault();

            $(e.currentTarget).addClass('ring-loader');
            const url = $(e.currentTarget).attr('href');

            if(!$(e.currentTarget).attr("disabled")) {
                $(e.currentTarget).attr("disabled", "disabled");
                $.ajax({
                    method: 'GET',
                    url: url,
                    success: function (data) {
                        $(e.currentTarget).removeClass('ring-loader');
                        $(e.currentTarget).removeAttr("disabled");
                        $('.modal').modal('hide');
                        ajaxModalBody.html(data);
                        ajaxModal.modal('show');
                    },
                    error: function (data) {
                        $(e.currentTarget).removeClass('ring-loader');
                        $(e.currentTarget).removeAttr("disabled");
                        $('.modal').modal('hide');
                    }
                })
            }
        });
    })
})(jQuery);